// import axios from '@/util/ajax'
const state = {
    // 学生状态
    stuState:[
        {
            value:'0',
            name:'在读'
        },
        {
            value:'1',
            name:'毕业'
        }
    ]
   
}
export default {
    state,
}