/*
 * @Author: awenyjw
 * @Date: 2023-04-10 13:30:27
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-06-26 15:46:27
 * @FilePath: \hc-smartSupervision\src\utils\permissions.js
 * @Description: 
 * 
 */
import store from '@/store'

export function actionToObject (json) {
  try {
    return JSON.parse(json)
  } catch (e) {
  }
  return []
}

/**
 * 控制按钮是否显示
 *
 * @author awenyjw
 * @date 2023年5月15日13:58:18
 */
export function hasBtnPermission (permission) {
  const myBtns = store.getters.buttons
  const allBtns = store.getters.allButtons
  const admintype = store.getters.admintype
  // eslint-disable-next-line eqeqeq
  if (admintype == '1') {
     return true
  }
  // if (admintype == '2') {
  //    return true
  // }
  // 所有系统按钮中不存在，则不限制
  return allBtns.indexOf(permission) === -1 || myBtns.indexOf(permission) > -1
}

//判断是否需要显示操作按钮
export function isSomePermission (...btns) {
  return btns.map(item=>{
    return hasBtnPermission(item)
  }).every(one=>{
    return one
  })
}

