import Vue from 'vue'
import router from './router'
import store from './store'

import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

import { ACCESS_TOKEN, ALL_APPS_MENU, ALL_Menus } from '@/store/mutation-types'

import { Modal, notification } from 'ant-design-vue' // NProgress Configuration
import { timeFix } from '@/utils/util' /// es/notification
import Enumerable from 'linq'
import localMenus from './localMenus'
NProgress.configure({ showSpinner: false })
const whiteList = ['login', 'register', 'registerResult', 'wechat'] // no redirect whitelist
// 无默认首页的情况
const defaultRoutePath = '/socialDevelopment'
// const defaultRoutePath = '/home'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`)
  // to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))
  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    if (to.path === '/user/login') {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        console.log('store.getters.roles.length === 0')
        store
          .dispatch('GetInfo')
          .then(res => {
            // console.log(res, '切换公司拉取的user信息')
            if (res.menus.length < 1) {
              // console.log('res.menus.length < 1')
              // console.log(router.options.routes, '===========打印所有路由')
              next('/keep/home')
              return
            }

            const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
            const all_menus = Vue.ls.get(ALL_Menus)

            let antDesignmenus
            // eslint-disable-next-line camelcase
            if (all_app_menu == null) {
              const applocation = []
              res.apps.forEach(item => {
                const apps = { code: '', name: '', active: '', menu: '' }
                if (item.active) {
                  apps.code = item.code
                  apps.name = item.name
                  apps.active = item.active
                  apps.menu = res.menus
                  antDesignmenus = res.menus
                  // antDesignmenus = localMenus
                } else {
                  apps.code = item.code
                  apps.name = item.name
                  apps.active = item.active
                  apps.menu = ''
                }
                applocation.push(apps)
              })
              if (antDesignmenus === undefined) {
                // 没有设置默认值的情况下，使第一个应用菜单为默认菜单
                var firstApps = Enumerable.from(applocation).first()
                firstApps.active = 'Y'
                firstApps.menu = res.menus
                antDesignmenus = res.menus
                // antDesignmenus = localMenus
              }

              Vue.ls.set(ALL_APPS_MENU, applocation, 7 * 24 * 60 * 60 * 1000) //缓存7天
              Vue.ls.set(ALL_Menus, applocation, 7 * 24 * 60 * 60 * 1000) //缓存7天

              // 延迟 1 秒显示欢迎信息
              setTimeout(() => {
                notification.success({
                  message: '欢迎',
                  description: `${timeFix()}，欢迎回来`
                })
              }, 1000)
            } else {
              antDesignmenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
              // antDesignmenus = localMenus
            }

            store.dispatch('GenerateRoutes', { antDesignmenus }).then(() => {
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                if (to.path == '/keep/home' || redirect == '/keep/home') {
                  next({ path: '/socialDevelopment' })
                } else {
                  next({ path: redirect })
                  // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                  next({ ...to, replace: true })
                }
              } else {
                next(to.path)
              }
            })
          })
          .catch(() => {
            // debugger
            store.dispatch('Logout').then(() => {
              next({ path: '/user/login', query: { redirect: to.fullPath } })
            })
          })
        store.dispatch('getNoticReceiveList').then(res => {})
      } else {
        // debugger
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/user/login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
