import moment from 'moment'
import Vue from 'vue'
import { DICT_TYPE_TREE_DATA } from '@/store/mutation-types'

// 获取当前时间
export function getCurrentTime() {
  return timeFormate(new Date(new Date().getTime()))
}
// 通过code（字段名）过滤对应字段
export function handleFindName(id, arr, code) {
  if (id != null && id != undefined && arr.length) {
    let obj = arr.find(item => {
      return item.id == id
    })
    return obj?.name
  } else {
    return ''
  }
}

// 通过code过滤字典
export function filterDictType(code, dictTypeArr) {
  if (code && dictTypeArr) {
    let obj = dictTypeArr.find(item => {
      return item.code == code
    })
    return obj.children
  } else {
    return []
  }
}

export function extractChinese(str) {
  let regExp = /[\u4e00-\u9fa5]+/g
  let result = str.match(regExp)
  if (result) {
    return result.join('')
  } else {
    return ''
  }
}

/**
 * 获取某个code下字典的列表，多用于字典下拉框
 * 示例：findDictList('project_type')
 * @author awenyjw
 * @date 2023-9-22 15:13:25
 */
export function findDictList(code) {
  const dictTypeTree = Vue.ls.get(DICT_TYPE_TREE_DATA)
  if (dictTypeTree == undefined || dictTypeTree == null) {
    // 如果应用为空重新去拉取接口
    let result = null
    sysDictTypeTree()
      .then(data => {
        if (data.success) {
          result = data.data
          Vue.ls.set(DICT_TYPE_TREE_DATA, result)
        } else {
        }
      })
      .catch(error => {
        reject(error)
      })
    return result ?? []
  }
  const tree = dictTypeTree.filter(item => item.code == code)[0]?.children
  if (tree == undefined || tree == null) {
    return []
  }
  return tree
}

// 万元,保留三位小数,在整数部分每三位添加一个逗号
export function formatToTenThousandWithCustomCommas(amount) {

  if (amount) {
    amount = Number(amount);
    let resultInTenThousand = amount.toFixed(3);
    let integerPart = resultInTenThousand.split('.')[0];
    let decimalPart = '.' + resultInTenThousand.split('.')[1];

    // 在整数部分每三位添加一个逗号
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return integerPart + decimalPart;
  } else {
    return ''
  }

}


// 元,保留两位小数,在整数部分每三位添加一个逗号
export function formatNumericWithTwoDecimalPlaces(amount) {

  if (amount) {
    amount = Number(amount);

    // 将金额转换为字符串，并分割出整数部分和小数部分
    let parts = amount.toFixed(2).split('.');

    // 对整数部分应用千位分隔符
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // 添加小数部分（始终有三位）
    let decimalPart = '.' + (parts[1] || '000');

    // 合并整数部分和小数部分
    return integerPart + decimalPart;
  } else {
    return ''
  }

}

// 万人,保留2位小数
export function formatTwoDecimalPlaces(amount) {

  if (amount) {
    amount = Number(amount);
    let parts = amount.toFixed(2);
    return parts
  } else {
    return ''
  }

}
// 时间回显追加8小时
export function timeEchoFormate(value, formatType = 'YYYY-MM-DD HH:mm') {
  return value
    ? moment(value)
      .add(0, 'h')
      .format(formatType)
    : ' '
}


// 格式化时间
export function timeFormate(value, formatType = 'YYYY-MM-DD HH:mm:ss') {
  return moment(value).format(formatType)
}
// 获取天气
export function getWeather() {
  this.$jsonp('https://api.asilu.com/weather_v2/').then(res => {
    if (res.status == '1') {
      const city = res.forecasts[0].city
      let daily_forecast = res.forecasts[0].casts[0]
      let [code, txt] = ['', '']
      if (new Date().getHours() >= 6 && new Date().getHours() < 18) {
        txt = daily_forecast.dayweather
        // 温度范围
        // this.temperatureTxt = daily_forecast.nighttemp + "℃~" + daily_forecast.daytemp + "℃";
        // 单点温度
        this.temperatureTxt = daily_forecast.daytemp + '℃'
      } else {
        txt = daily_forecast.nightweather
        // 温度范围
        // this.temperatureTxt = daily_forecast.daytemp + "℃~" + daily_forecast.nighttemp + "℃";
        // 单点温度
        this.temperatureTxt = daily_forecast.nighttemp + '℃'
      }
      this.weatherCity = city // 城市
      this.weatherStatus = txt // 天气状态
      const weather2Code = {
        晴: 100,
        多云: 101,
        少云: 102,
        晴间多云: 103,
        阴: 104,
        有风: 200,
        平静: 201,
        微风: 202,
        和风: 203,
        清风: 204,
        '强风/劲风': 205,
        疾风: 206,
        大风: 207,
        烈风: 208,
        风暴: 209,
        狂爆风: 210,
        飓风: 211,
        龙卷风: 212,
        热带风暴: 213,
        阵雨: 300,
        强阵雨: 301,
        雷阵雨: 302,
        强雷阵雨: 303,
        雷阵雨伴有冰雹: 304,
        小雨: 305,
        中雨: 306,
        大雨: 307,
        极端降雨: 308,
        '毛毛雨/细雨': 309,
        暴雨: 310,
        大暴雨: 311,
        特大暴雨: 312,
        冻雨: 313,
        小到中雨: 314,
        中到大雨: 315,
        大到暴雨: 316,
        暴雨到大暴雨: 317,
        大暴雨到特大暴雨: 318,
        雨: 399,
        小雪: 400,
        中雪: 401,
        大雪: 402,
        暴雪: 403,
        雨夹雪: 404,
        雨雪天气: 405,
        阵雨夹雪: 406,
        阵雪: 407,
        小到中雪: 408,
        中到大雪: 409,
        大到暴雪: 410,
        雪: 499,
        薄雾: 500,
        雾: 501,
        霾: 502,
        扬沙: 503,
        浮尘: 504,
        沙尘暴: 507,
        强沙尘暴: 508,
        浓雾: 509,
        强浓雾: 510,
        中度霾: 511,
        重度霾: 512,
        严重霾: 513,
        大雾: 514,
        特强浓雾: 515,
        热: 900,
        冷: 901,
        未知: 999
      }
      this.weatherImg = `https://cdn.heweather.com/cond_icon/${weather2Code[txt]}.png`
    } else {
      this.$message.error(res.message)
    }
  })
}
// 天气图标枚举
export const weather2Code = {
  晴: 100,
  多云: 101,
  少云: 102,
  晴间多云: 103,
  阴: 104,
  有风: 200,
  平静: 201,
  微风: 202,
  和风: 203,
  清风: 204,
  '强风/劲风': 205,
  疾风: 206,
  大风: 207,
  烈风: 208,
  风暴: 209,
  狂爆风: 210,
  飓风: 211,
  龙卷风: 212,
  热带风暴: 213,
  阵雨: 300,
  强阵雨: 301,
  雷阵雨: 302,
  强雷阵雨: 303,
  雷阵雨伴有冰雹: 304,
  小雨: 305,
  中雨: 306,
  大雨: 307,
  极端降雨: 308,
  '毛毛雨/细雨': 309,
  暴雨: 310,
  大暴雨: 311,
  特大暴雨: 312,
  冻雨: 313,
  小到中雨: 314,
  中到大雨: 315,
  大到暴雨: 316,
  暴雨到大暴雨: 317,
  大暴雨到特大暴雨: 318,
  雨: 399,
  小雪: 400,
  中雪: 401,
  大雪: 402,
  暴雪: 403,
  雨夹雪: 404,
  雨雪天气: 405,
  阵雨夹雪: 406,
  阵雪: 407,
  小到中雪: 408,
  中到大雪: 409,
  大到暴雪: 410,
  雪: 499,
  薄雾: 500,
  雾: 501,
  霾: 502,
  扬沙: 503,
  浮尘: 504,
  沙尘暴: 507,
  强沙尘暴: 508,
  浓雾: 509,
  强浓雾: 510,
  中度霾: 511,
  重度霾: 512,
  严重霾: 513,
  大雾: 514,
  特强浓雾: 515,
  热: 900,
  冷: 901,
  未知: 999
}
// 获取当前时间
export function getTime() {
  var _this = this
  let yy = new Date().getFullYear()
  var mm =
    new Date().getMonth() > 9
      ? new Date().getMonth() + 1
      : new Date().getMonth() == 9
        ? new Date().getMonth() + 1
        : '0' + (new Date().getMonth() + 1)
  var dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
  let hh = new Date().getHours()
  let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
  let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
  _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
}

export function dateFormat(dateString, fmt) {
  let date = new Date(dateString)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 LOL', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = s => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
