// with polyfills
if (window.__POWERED_BY_QIANKUN__) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
// import { qiankunRuter } from './router'
// console.log(qiankunRuter,'qiankunRuter');
// let router = qiankunRuter
import router from './router'
// console.log(router, 'router111');
import store from './store/'
import { VueAxios } from './utils/request'
import VueRouter from 'vue-router'
// Vue.use(VueRouter);
import bootstrap1 from './core/bootstrap'
import './core/lazy_use'
import './permission'
import './utils/filter'
import './components/global.less'
import { Dialog } from '@/components'
import { hasBtnPermission, isSomePermission } from './utils/permissions'
import {
  formatToTenThousandWithCustomCommas,
  formatNumericWithTwoDecimalPlaces,
  formatTwoDecimalPlaces,
  timeFormate,
  timeEchoFormate,
  filterDictType,
  handleFindName,
  getCurrentTime,
  findDictList
} from './utils/util'
import { sysApplication } from './utils/applocation'

import '@/common/common.css'
import '@/common/element.scss'
import 'ant-design-vue/dist/antd.less'

import { FormModel } from 'ant-design-vue'
Vue.use(FormModel)

import videojs from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = videojs

// 引入Viewer
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)

// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入jsonp请求
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

Vue.use(VueAxios)
Vue.use(Dialog)
Vue.prototype.hasPerm = hasBtnPermission
Vue.prototype.isSomePern = isSomePermission
Vue.prototype.applocation = sysApplication
Vue.prototype.formatToTenThousandWithCustomCommas = formatToTenThousandWithCustomCommas
Vue.prototype.formatNumericWithTwoDecimalPlaces = formatNumericWithTwoDecimalPlaces
Vue.prototype.formatTwoDecimalPlaces = formatTwoDecimalPlaces


Vue.prototype.timeEchoFormate = timeEchoFormate
Vue.prototype.filterDictType = filterDictType
Vue.prototype.timeFormate = timeFormate
Vue.prototype.handleFindName = handleFindName
Vue.prototype.getCurrentTime = getCurrentTime
Vue.prototype.findDictList = findDictList
Vue.config.productionTip = false

// qiankun路由拦截
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle } from '@/utils/domUtil'

import { ACCESS_TOKEN, ALL_APPS_MENU, ALL_Menus } from '@/store/mutation-types'

import { Modal, notification } from 'ant-design-vue' // NProgress Configuration
import { timeFix } from '@/utils/util' /// es/notification
import Enumerable from 'linq'
import localMenus from './localMenus'
NProgress.configure({ showSpinner: false })
const whiteList = ['login', 'register', 'registerResult', 'wechat'] // no redirect whitelist
// 无默认首页的情况
const defaultRoutePath = '/socialDevelopment'

export async function bootstrap(props = {}) {


  console.log('[vue] vue app bootstraped')
}
export async function mount(props) {
  console.log('[vue] props from main framework', props)
  render(props)
}
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  // router = null
}

// 全局注册防抖
Vue.component('ElButton').mixin({
  data() {
    return {
      debounce: false
    }
  },
  methods: {
    //覆盖el-button的点击事件，使用的是vue2.5.2，发现为直接覆写了原方法
    handleClick(evt) {
      if (this.debounce) {
        this.$message.warning('频繁点击，请稍后再试！')
      } else {
        this.debounce = true
        this.$emit('click', evt)
        setTimeout(() => {
          this.debounce = false
        }, 1000) //延时时间
      }
    }
  }
})

// qiankun子项目配置
let subRouter = null
let instance = null
import { constantRouterMap } from '@/config/router.config'
console.log(constantRouterMap, 'constantRouterMap')
function render(props = {}) {
  console.log(props, '---');
  const { container, accessToken, userData } = props

  subRouter = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? '/sub-ymhf' : '/',
    mode: 'history',
    routes: constantRouterMap
  })

  Vue.ls.set('ACCESS_TOKEN', accessToken) /*, 7 * 24 * 60 * 60 * 1000 */
  delete userData.menus
  subRouter.beforeEach((to, from, next) => {
    NProgress.start()
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`)

    if (to.path === '/user/login' || to.path === '') {

      if (!Vue.ls.get(ACCESS_TOKEN)) {
        store.dispatch('YMHFLogin', userData).then(() => {
          next({ path: '/socialDevelopment' })
          NProgress.done()
        })
      } else {
        if (store.getters.roles.length === 0) {
          console.log('store.getters.roles.length === 0')
          store
            .dispatch('GetInfo')
            .then(res => {
              console.log(res, '切换公司拉取的user信息')
              debugger;
              if (res.menus.length < 1) {
                next('/keep/home')
                return
              }
              const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
              let antDesignmenus
              if (all_app_menu == null) {
                const applocation = []
                res.apps.forEach(item => {
                  const apps = { code: '', name: '', active: '', menu: '' }
                  if (item.active) {
                    apps.code = item.code
                    apps.name = item.name
                    apps.active = item.active
                    apps.menu = res.menus
                    antDesignmenus = res.menus
                  } else {
                    apps.code = item.code
                    apps.name = item.name
                    apps.active = item.active
                    apps.menu = ''
                  }
                  applocation.push(apps)
                })
                if (antDesignmenus === undefined) {
                  var firstApps = Enumerable.from(applocation).first()
                  firstApps.active = 'Y'
                  firstApps.menu = res.menus
                  antDesignmenus = res.menus
                }

                Vue.ls.set(ALL_APPS_MENU, applocation, 7 * 24 * 60 * 60 * 1000) //缓存7天
                Vue.ls.set(ALL_Menus, applocation, 7 * 24 * 60 * 60 * 1000) //缓存7天

                // 延迟 1 秒显示欢迎信息
                setTimeout(() => {
                  notification.success({
                    message: '欢迎',
                    description: `${timeFix()}，欢迎回来`
                  })
                }, 1000)
              } else {
                antDesignmenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
              }

              store.dispatch('GenerateRoutes', { antDesignmenus }).then(() => {
                subRouter.addRoutes(store.getters.addRouters)
                const redirect = decodeURIComponent(from.query.redirect || to.path)
                if (to.path === redirect) {
                  if (to.path == '/keep/home' || redirect == '/keep/home') {
                    next({ path: '/socialDevelopment' })
                  } else {
                    next({ path: redirect })
                    next({ ...to, replace: true })
                  }
                } else {
                  next(to.path)
                }
              })
            })
            .catch(() => {
              store.dispatch('Logout').then(() => {
                next({ path: '/user/login', query: { redirect: to.fullPath } })
              })
            })
          store.dispatch('getNoticReceiveList').then(res => { })
        } else {
          next({ path: '/socialDevelopment' })
        }
      }

    } else {
      if (Vue.ls.get(ACCESS_TOKEN)) {
        /* has token */
        if (to.path === '/user/login') {
          // next({ path: defaultRoutePath })
          // NProgress.done()
          store.dispatch('YMHFLogin', userData).then(() => {
            next({ path: '/socialDevelopment' })
            NProgress.done()
          })
        } else {
          if (store.getters.roles.length === 0) {
            console.log('store.getters.roles.length === 0')
            store
              .dispatch('GetInfo')
              .then(res => {
                console.log(res, '切换公司拉取的user信息')
                if (res.menus.length < 1) {
                  next('/keep/home')
                  return
                }
                const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
                let antDesignmenus
                if (all_app_menu == null) {
                  const applocation = []
                  res.apps.forEach(item => {
                    const apps = { code: '', name: '', active: '', menu: '' }
                    if (item.active) {
                      apps.code = item.code
                      apps.name = item.name
                      apps.active = item.active
                      apps.menu = res.menus
                      antDesignmenus = res.menus
                    } else {
                      apps.code = item.code
                      apps.name = item.name
                      apps.active = item.active
                      apps.menu = ''
                    }
                    applocation.push(apps)
                  })
                  if (antDesignmenus === undefined) {
                    var firstApps = Enumerable.from(applocation).first()
                    firstApps.active = 'Y'
                    firstApps.menu = res.menus
                    antDesignmenus = res.menus
                  }

                  Vue.ls.set(ALL_APPS_MENU, applocation, 7 * 24 * 60 * 60 * 1000) //缓存7天
                  Vue.ls.set(ALL_Menus, applocation, 7 * 24 * 60 * 60 * 1000) //缓存7天

                  // 延迟 1 秒显示欢迎信息
                  setTimeout(() => {
                    notification.success({
                      message: '欢迎',
                      description: `${timeFix()}，欢迎回来`
                    })
                  }, 1000)
                } else {
                  antDesignmenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
                }

                store.dispatch('GenerateRoutes', { antDesignmenus }).then(() => {
                  subRouter.addRoutes(store.getters.addRouters)
                  const redirect = decodeURIComponent(from.query.redirect || to.path)
                  if (to.path === redirect) {
                    if (to.path == '/keep/home' || redirect == '/keep/home') {
                      next({ path: '/socialDevelopment' })
                    } else {
                      next({ path: redirect })
                      next({ ...to, replace: true })
                    }
                  } else {
                    next(to.path)
                  }
                })
              })
              .catch(() => {
                store.dispatch('Logout').then(() => {
                  next({ path: '/user/login', query: { redirect: to.fullPath } })
                })
              })
            store.dispatch('getNoticReceiveList').then(res => { })
          } else {
            next()
          }
        }
      }

      else {
        //
        // if (whiteList.includes(to.name)) {
        //   // 在免登录白名单，直接进入
        //   next()
        // } else {
        //   next({ path: '/user/login', query: { redirect: to.fullPath } })
        //   NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
        // }
      }
    }

  })

  subRouter.afterEach(() => {
    NProgress.done() // finish progress bar
  })

  subRouter.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending?.fullPath;
    if (isChunkLoadFailed) {
      router.replace(targetPath);
    }
  })


  instance = new Vue({
    router: subRouter,
    store,
    created: bootstrap1,
    render: h => h(App)
  }).$mount('#sub-app')
}




// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  new Vue({
    router,
    store,
    created: bootstrap1,
    render: h => h(App)
  }).$mount('#sub-app')
}
