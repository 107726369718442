<template>
  <a-config-provider :locale="locale">
    <div id="app" class="app app1" :style="{ height: isQuankun ? 'calc(100vh - 50px)' : '100%' }">
      <router-view class="scrollbar" />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'

export default {
  mixins: [AppDeviceEnquire],
  data() {
    return {
      locale: zhCN,
      isQuankun: null
    }
  },
  created() {
    this.$nextTick(() => {
      this.resizeFun()
      window.addEventListener('resize', this.resizeFun)
    })
  },
  mounted() {
    this.isQuankun = window.__POWERED_BY_QIANKUN__
    if (typeof window !== 'undefined') {
      // 在这里访问 window 对象的属性
      console.log(window)
    }
  },
  methods: {
    resizeFun() {
      const devicePixelRatio = window.devicePixelRatio // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      if (devicePixelRatio !== 1) {
        // 如果在笔记本中用IE浏览器打开 则弹出提示
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          alert('请使用谷歌、edge等浏览器！')
        } else {
          const c = document.querySelector('body')
          c.style.zoom = 1 / devicePixelRatio // 修改页面的缩放比例
        }
      }
    }
  }
}
</script>
<style lang="scss" scope>
.app {
  /* overflow: hidden scroll; */
  border: none;
  padding-bottom: 15px;
  /* background-color: #f0f2f5; */
  background-color: #f6f7f9;
}

.scrollbar {
  margin: 0 auto;
}

/* 滚动条样式 */
/* .app1::-webkit-scrollbar {
  width: 8px;
}

.app1::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #aaa;
}

.app1::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #ffffff;
} */

.el-select {
  width: 100% !important;
}




</style>

