<!--
 * @Author: awenyjw
 * @Date: 2023-04-10 13:30:25
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-06-26 14:57:12
 * @FilePath: \hc-smartSupervision\src\components\tools\Logo.vue
 * @Description: 
 * 
-->
<template>
  <!-- <div :class="['system-logo', collapsed == true ? 'flex-center' : '']"> -->
  <div :class="[collapsed == true ? 'center-logo' : 'system-logo']">
    <div class="flex-center">
      <h1 v-if="!collapsed" :class="[navTheme == 'dark' ? 'white-title' : 'blue-title']">移民后扶</h1>
    </div>
    <!-- </router-link> -->
  </div>
</template>

<script>
import { mixin, mixinDevice } from '@/utils/mixin'

export default {
  name: 'Logo',
  components: {
  },
  mixins: [mixin, mixinDevice],
  data() {
    return {
      titles: ''
    }
  },
  props: {
    title: {
      type: String,
      default: '海策云监理',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    if (this.layoutMode === 'topmenu') {
      if (this.title.length > 8) {
        this.titles = this.title.substring(0, 8) + '...'
      } else {
        this.titles = this.title
      }
    } else {
      if (this.title.length > 10) {
        this.titles = this.title.substring(0, 8) + '...'
      } else {
        this.titles = this.title
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.system-logo {
  display: flex;
  height: 55px;
  & > div:nth-child(1) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div:nth-child(2) {
    flex: 3;
    h1 {
      color: #fff;
      font-size: 20px;
      margin: 0 0 0 12px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      font-weight: 600;
      display: block;
      flex: 2;
      font-family: PangMenZhengDao;
      font-size: 30px;
      font-weight: normal;
      line-height: 56px;
      letter-spacing: 0px;

      color: #3f8cff;
    }
  }
}
.center-logo {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div:nth-child(1) {
    img {
      display: block;
      height: 32px;
      width: 32px;
      position: unset !important;
    }
  }
}
.white-title {
  color: white !important;
}
.blue-title {
  color: #1890ff !important;
}
</style>
