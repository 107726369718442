
// 1.项目基本信息
const proBasicInfo = [
    {
        "id": 110,
        "pid": 0,
        "name": "supervision_proHome",
        "component": "supervision/proHome/index.vue",
        "redirect": "",
        "meta": {
            "title": "首页",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/proHome",
        "hidden": false
    },
    {
        "id": 111,
        "pid": 0,
        "name": "supervision_proBasicInfo",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "项目基本信息",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/proBasicInfo",
        "hidden": false
    },
    {
        "id": 1001,
        "pid": 111,
        "name": "supervision_proBasicInfo_SupProInfo",
        "component": "supervision/proBasicInfo/SupProInfo/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理项目信息",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupProInfo",
        "hidden": false
    },
    {
        "id": 1002,
        "pid": 111,
        "name": "supervision_proBasicInfo_BuildUnits",
        "component": "supervision/proBasicInfo/BuildUnits/index.vue",
        "redirect": "",
        "meta": {
            "title": "参建单位",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "BuildUnits",
        "hidden": false
    },
    {
        "id": 1003,
        "pid": 111,
        "name": "supervision_proBasicInfo_MonEquipInfo",
        "component": "supervision/proBasicInfo/MonEquipInfo/index.vue",
        "redirect": "",
        "meta": {
            "title": "监控设备信息",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "MonEquipInfo",
        "hidden": false
    },
    {
        "id": 1004,
        "pid": 111,
        "name": "supervision_proBasicInfo_ProAddbook",
        "component": "supervision/proBasicInfo/ProAddbook/index.vue",
        "redirect": "",
        "meta": {
            "title": "项目通讯录",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "ProAddbook",
        "hidden": false
    },
]
// 2.监理准备
const supPrepare = [
    {
        "id": 222,
        "pid": 0,
        "name": "supervision_supPrepare",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "监理准备",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/supPrepare",
        "hidden": false
    },
    {
        "id": 2001,
        "pid": 222,
        "name": "supervision_supPrepare_SupPlan",
        "component": "supervision/supPrepare/SupPlan/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理规划",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupPlan",
        "hidden": false
    },
    {
        "id": 2002,
        "pid": 222,
        "name": "supervision_supPrepare_SupConDis",
        "component": "supervision/supPrepare/SupConDis/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理合同交底",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupConDis",
        "hidden": false
    },
    {
        "id": 2003,
        "pid": 222,
        "name": "supervision_supPrepare_StartPlan",
        "component": "supervision/supPrepare/StartPlan/index.vue",
        "redirect": "",
        "meta": {
            "title": "开工策划",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "StartPlan",
        "hidden": false
    },
]
// 3.施工前准备
const buildPrepare = [
    {
        "id": 333,
        "pid": 0,
        "name": "supervision_buildPrepare",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "施工前准备",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/buildPrepare",
        "hidden": false
    },
    {
        "id": 3001,
        "pid": 333,
        "name": "supervision_buildPrepare_FirstSiteMeet",
        "component": "supervision/buildPrepare/FirstSiteMeet/index.vue",
        "redirect": "",
        "meta": {
            "title": "第一次工地会议",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "FirstSiteMeet",
        "hidden": false
    },
    {
        "id": 3002,
        "pid": 333,
        "name": "supervision_buildPrepare_DesDisDraRev",
        "component": "supervision/buildPrepare/DesDisDraRev/index.vue",
        "redirect": "",
        "meta": {
            "title": "设计交底与图纸会审",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "DesDisDraRev",
        "hidden": false
    },
    {
        "id": 3003,
        "pid": 333,
        "name": "supervision_buildPrepare_SupRules",
        "component": "supervision/buildPrepare/SupRules/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理细则(文档)",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupRules",
        "hidden": false
    },
    {
        "id": 3004,
        "pid": 333,
        "name": "supervision_buildPrepare_SupPlanRuDis",
        "component": "supervision/buildPrepare/SupPlanRuDis/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理规划/细则交底",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupPlanRuDis",
        "hidden": false
    },
    {
        "id": 3005,
        "pid": 333,
        "name": "supervision_buildPrepare_WorkRepCom",
        "component": "supervision/buildPrepare/WorkRepCom/index.vue",
        "redirect": "",
        "meta": {
            "title": "开工报告/开工令",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "WorkRepCom",
        "hidden": false
    },

]
// 4.资质审查
const qualifReview = [
    {
        "id": 444,
        "pid": 0,
        "name": "supervision_qualifReview",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "资质审查",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/qualifReview",
        "hidden": false
    },
    {
        "id": 4001,
        "pid": 444,
        "name": "supervision_qualifReview_BuildMagPer",
        "component": "supervision/qualifReview/BuildMagPer/index.vue",
        "redirect": "",
        "meta": {
            "title": "施工管理人员",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "BuildMagPer",
        "hidden": false
    },
    {
        "id": 4002,
        "pid": 444,
        "name": "supervision_qualifReview_SafeMagPer",
        "component": "supervision/qualifReview/SafeMagPer/index.vue",
        "redirect": "",
        "meta": {
            "title": "安全管理人员",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SafeMagPer",
        "hidden": false
    },
    {
        "id": 4003,
        "pid": 444,
        "name": "supervision_qualifReview_SpePerQuali",
        "component": "supervision/qualifReview/SpePerQuali/index.vue",
        "redirect": "",
        "meta": {
            "title": "特种作业人员资质",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SpePerQuali",
        "hidden": false
    },
    {
        "id": 4004,
        "pid": 444,
        "name": "supervision_qualifReview_BuildUnitsQuali",
        "component": "supervision/qualifReview/BuildUnitsQuali/index.vue",
        "redirect": "",
        "meta": {
            "title": "参建单位资质",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "BuildUnitsQuali",
        "hidden": false
    },


]
// 5.监理工作
const supWork = [
    {
        "id": 555,
        "pid": 0,
        "name": "supervision_supWork",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "监理工作",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/supWork",
        "hidden": false
    },
    {
        "id": 5001,
        "pid": 555,
        "name": "supervision_supWork_AddCheck",
        "component": "supervision/supWork/AddCheck/index.vue",
        "redirect": "",
        "meta": {
            "title": "新增检查项",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "AddCheck",
        "hidden": false
    },
    {
        "id": 5002,
        "pid": 555,
        "name": "supervision_supWork_PatrolReg",
        "component": "supervision/supWork/PatrolReg/index.vue",
        "redirect": "",
        "meta": {
            "title": "巡查登记",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "PatrolReg",
        "hidden": false
    },
    {
        "id": 5003,
        "pid": 555,
        "name": "supervision_supWork_SideStaReg",
        "component": "supervision/supWork/SideStaReg/index.vue",
        "redirect": "",
        "meta": {
            "title": "旁站登记",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SideStaReg",
        "hidden": false
    },
    {
        "id": 5004,
        "pid": 555,
        "name": "supervision_supWork_CheAcReg",
        "component": "supervision/supWork/CheAcReg/index.vue",
        "redirect": "",
        "meta": {
            "title": "验收登记",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "CheAcReg",
        "hidden": false
    },
    {
        "id": 5005,
        "pid": 555,
        "name": "supervision_supWork_MatEquipInfo",
        "component": "supervision/supWork/MatEquipInfo/index.vue",
        "redirect": "",
        "meta": {
            "title": "材料设备信息",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "MatEquipInfo",
        "hidden": false
    },
    {
        "id": 5006,
        "pid": 555,
        "name": "supervision_supWork_MaterialIn",
        "component": "supervision/supWork/MaterialIn/index.vue",
        "redirect": "",
        "meta": {
            "title": "材料进场",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "MaterialIn",
        "hidden": false
    },
    {
        "id": 5007,
        "pid": 555,
        "name": "supervision_supWork_EquipmentIn",
        "component": "supervision/supWork/EquipmentIn/index.vue",
        "redirect": "",
        "meta": {
            "title": "设备进场",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "EquipmentIn",
        "hidden": false
    },
    {
        "id": 5008,
        "pid": 555,
        "name": "supervision_supWork_WitnessSampl",
        "component": "supervision/supWork/WitnessSampl/index.vue",
        "redirect": "",
        "meta": {
            "title": "见证取样",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "WitnessSampl",
        "hidden": false
    },
    {
        "id": 5009,
        "pid": 555,
        "name": "supervision_supWork_TestWitness",
        "component": "supervision/supWork/TestWitness/index.vue",
        "redirect": "",
        "meta": {
            "title": "试块试件见证",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "TestWitness",
        "hidden": false
    },
    {
        "id": 5010,
        "pid": 555,
        "name": "supervision_supWork_MatParTest",
        "component": "supervision/supWork/MatParTest/index.vue",
        "redirect": "",
        "meta": {
            "title": "材料平行监测",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "MatParTest",
        "hidden": false
    },
    {
        "id": 5011,
        "pid": 555,
        "name": "supervision_supWork_EntParTest",
        "component": "supervision/supWork/EntParTest/index.vue",
        "redirect": "",
        "meta": {
            "title": "实体平行监测",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "EntParTest",
        "hidden": false
    },
    // {
    //     "id": 5012,
    //     "pid": 555,
    //     "name": "supervision_supWork_EntParTest",
    //     "component": "supervision/supWork/EntParTest/index.vue",
    //     "redirect": "",
    //     "meta": {
    //         "title": "实体平行监测",
    //         "icon": null,
    //         "show": true,
    //         "target": "",
    //         "link": null
    //     },
    //     "path": "EntParTest",
    //     "hidden": false
    // },
    {
        "id": 5013,
        "pid": 555,
        "name": "supervision_supWork_SupNotice",
        "component": "supervision/supWork/SupNotice/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理通知单",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupNotice",
        "hidden": false
    },
    {
        "id": 5014,
        "pid": 555,
        "name": "supervision_supWork_WorkConForm",
        "component": "supervision/supWork/WorkConForm/index.vue",
        "redirect": "",
        "meta": {
            "title": "工作联系单",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "WorkConForm",
        "hidden": false
    },
    {
        "id": 5015,
        "pid": 555,
        "name": "supervision_supWork_ProTraLedger",
        "component": "supervision/supWork/ProTraLedger/index.vue",
        "redirect": "",
        "meta": {
            "title": "问题跟踪台账",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "ProTraLedger",
        "hidden": false
    },
    {
        "id": 5016,
        "pid": 555,
        "name": "supervision_supWork_SupDiaryList",
        "component": "supervision/supWork/SupDiaryList/index.vue",
        "redirect": "",
        "meta": {
            "title": "监理日记列表",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SupDiaryList",
        "hidden": false
    },
    {
        "id": 5017,
        "pid": 555,
        "name": "supervision_supWork_MeetMinutes",
        "component": "supervision/supWork/MeetMinutes/index.vue",
        "redirect": "",
        "meta": {
            "title": "会议纪要",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "MeetMinutes",
        "hidden": false
    },



]
// 6.危大工程管理
const dangEngMag = [
    {
        "id": 666,
        "pid": 0,
        "name": "supervision_dangEngMag",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "危大工程管理",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/dangEngMag",
        "hidden": false
    },
    {
        "id": 6001,
        "pid": 666,
        "name": "supervision_dangEngMag_SiteSafeLogo",
        "component": "supervision/dangEngMag/SiteSafeLogo/index.vue",
        "redirect": "",
        "meta": {
            "title": "现场安全警示标志",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "SiteSafeLogo",
        "hidden": false
    },
    {
        "id": 6002,
        "pid": 666,
        "name": "supervision_dangEngMag_BigEquipLedger",
        "component": "supervision/dangEngMag/BigEquipLedger/index.vue",
        "redirect": "",
        "meta": {
            "title": "大型机械设备及仪器台账",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "BigEquipLedger",
        "hidden": false
    },
    {
        "id": 6003,
        "pid": 666,
        "name": "supervision_dangEngMag_DanMoreEngMon",
        "component": "supervision/dangEngMag/DanMoreEngMon/index.vue",
        "redirect": "",
        "meta": {
            "title": "危险性较大工程安全监控",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "DanMoreEngMon",
        "hidden": false
    },
    {
        "id": 6004,
        "pid": 666,
        "name": "supervision_dangEngMag_OverEngMonitor",
        "component": "supervision/dangEngMag/OverEngMonitor/index.vue",
        "redirect": "",
        "meta": {
            "title": "超出一定规模工程安全监控",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "OverEngMonitor",
        "hidden": false
    },
    {
        "id": 6005,
        "pid": 666,
        "name": "supervision_dangEngMag_DanEngMonitor",
        "component": "supervision/dangEngMag/DanEngMonitor/index.vue",
        "redirect": "",
        "meta": {
            "title": "危险工程监控",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "DanEngMonitor",
        "hidden": false
    },
    {
        "id": 6006,
        "pid": 666,
        "name": "supervision_dangEngMag_DanPlanSubList",
        "component": "supervision/dangEngMag/DanPlanSubList/index.vue",
        "redirect": "",
        "meta": {
            "title": "危大方案提交列表",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "DanPlanSubList",
        "hidden": false
    },
    {
        "id": 6007,
        "pid": 666,
        "name": "supervision_dangEngMag_DanEngCleList",
        "component": "supervision/dangEngMag/DanEngCleList/index.vue",
        "redirect": "",
        "meta": {
            "title": "危大工程清除列表",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "DanEngCleList",
        "hidden": false
    },
    {
        "id": 6008,
        "pid": 666,
        "name": "supervision_dangEngMag_DanEngBuildList",
        "component": "supervision/dangEngMag/DanEngBuildList/index.vue",
        "redirect": "",
        "meta": {
            "title": "危大工程施工列表",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "DanEngBuildList",
        "hidden": false
    },



]
// 7.资质审查
const proClosing = [
    {
        "id": 777,
        "pid": 0,
        "name": "supervision_proClosing",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "资质审查",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/proClosing",
        "hidden": false
    },
    {
        "id": 7001,
        "pid": 777,
        "name": "supervision_proClosing_ProComFile",
        "component": "supervision/proClosing/ProComFile/index.vue",
        "redirect": "",
        "meta": {
            "title": "项目竣工资料归档",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "ProComFile",
        "hidden": false
    },
    {
        "id": 7002,
        "pid": 777,
        "name": "supervision_proClosing_ProAcReport",
        "component": "supervision/proClosing/ProAcReport/index.vue",
        "redirect": "",
        "meta": {
            "title": "项目验收报告",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "ProAcReport",
        "hidden": false
    },
    {
        "id": 7003,
        "pid": 777,
        "name": "supervision_proClosing_OwnerEval",
        "component": "supervision/proClosing/OwnerEval/index.vue",
        "redirect": "",
        "meta": {
            "title": "业主评价",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "OwnerEval",
        "hidden": false
    },

]
// 8.团队管理
const teamManage = [
    {
        "id": 888,
        "pid": 0,
        "name": "supervision_teamManage",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "团队管理",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/teamManage",
        "hidden": false
    },
    {
        "id": 8001,
        "pid": 888,
        "name": "supervision_teamManage_PersonManage",
        "component": "supervision/teamManage/PersonManage/index.vue",
        "redirect": "",
        "meta": {
            "title": "人员管理",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "PersonManage",
        "hidden": false
    }
]
// 9.总监应用 commissioner
const commissioner = [
    {
        "id": 999,
        "pid": 0,
        "name": "commissioner_Ledger",
        "component": "PageView",
        "redirect": "",
        "meta": {
            "title": "项目管理",
            "icon": "folder-open",
            "show": true,
            "target": "",
            "link": null
        },
        "path": "/Ledger",
        "hidden": false
    },
    {
        "id": 9001,
        "pid": 999,
        "name": "commissioner_Ledger_proInfo",
        "component": "commissioner/Ledger/proInfo/index.vue",
        "redirect": "",
        "meta": {
            "title": "项目信息",
            "icon": null,
            "show": true,
            "target": "",
            "link": null
        },
        "path": "commissioner/Ledger/proInfo",
        "hidden": false
    }
]
const antDesignmenus = [...proBasicInfo, ...supPrepare, ...buildPrepare, ...qualifReview, ...supWork, ...dangEngMag, ...proClosing, ...teamManage, ...commissioner]
export default antDesignmenus
