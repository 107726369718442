var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-popover',{attrs:{"trigger":"click","placement":"bottomRight","overlayClassName":"header-notice-wrapper","getPopupContainer":() => _vm.$refs.noticeRef.parentElement,"autoAdjustOverflow":true,"arrowPointAtCenter":true,"overlayStyle":{ width: '300px', top: '50px' }},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"content"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-tabs',_vm._l((_vm.userNotices.rows),function(item){return _c('a-tab-pane',{key:item.key,attrs:{"tab":item.key}},[_c('a-list',[_vm._l((item.noticeData.filter(m => {
            return m.type == item.value
          })),function(notice,index){return _c('a-list-item',{key:index},[_c('a-list-item-meta',{attrs:{"title":notice.title,"description":_vm.timeEchoFormate(notice.publicTime)}},[_c('a-avatar',{staticStyle:{"background-color":"white"},attrs:{"slot":"avatar","src":_vm.noticeImg},slot:"avatar"})],1)],1)}),(
              item.noticeData.filter(m => {
                return m.type == item.value
              }).length > 0
            )?_c('a-list-item',{staticStyle:{"display":"block","text-align":"center"}},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.goMore()}}},[_vm._v("去处理")])],1):_vm._e()],2)],1)}),1)],1)],1),_c('span',{ref:"noticeRef",staticClass:"header-notice",staticStyle:{"padding":"0 18px"},on:{"click":_vm.fetchNotice}},[_c('a-badge',{attrs:{"count":_vm.userNotices.totalRows}},[_c('a-icon',{staticStyle:{"font-size":"16px","padding":"4px"},attrs:{"type":"bell"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }