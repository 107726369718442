<template>
  <div class="wrap">
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
}
</style>
