import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/config/router.config'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
// const qiankunRuter = new Router({
//   // base: window.__POWERED_BY_QIANKUN__ ? '/subYmhf/' : process.env.BASE_URL,
//   base: process.env.BASE_URL,
//   scrollBehavior: () => ({ y: 0 }),
//   mode: 'history',
//   routes: constantRouterMap
// })
// export default qiankunRuter

export default new Router({
  // base: window.__POWERED_BY_QIANKUN__ ? '/subYmhf/' : process.env.BASE_URL,
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  routes: constantRouterMap
})
