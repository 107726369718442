<template>
  <div class="user-wrapper">
    <div class="content_box">
      <!--<a href="" target="_blank">
        <span class="action">
          <a-icon type="question-circle-o"></a-icon>
        </span>
      </a>-->
      <!-- <span @click="testToken">测试刷新token</span> -->
      <!-- <span @click="logGuid">生成guid</span> -->

      <!-- <span class="action" @click="toggleFullscreen">
        <a-icon type="fullscreen-exit" v-if="isFullscreen" />
        <a-icon type="fullscreen" v-else />
      </span> -->

      <!-- <notice-icon class="action" v-if="hasPerm('sysNotice:received')" /> -->

      <!-- <el-button size="medium" icon="el-icon-chat-line-round" @click="handleSetting" circle></el-button>

      <el-button size="medium" icon="el-icon-setting" circle></el-button> -->

      <a-dropdown>
        <div class="action ant-dropdown-link user-dropdown-menu">
          <div class="nickname">{{ nickname.slice(0, 1) }}</div>
        </div>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
         
          <a-menu-divider />
          <a-menu-item key="3">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout" />
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'

import Vue from 'vue'
import { message } from 'ant-design-vue/es'
import { _refreshToken } from '@/api/keepHome'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import store from '@/store'
import { _systenantMypage } from '@/api/keepHome'
export default {
  name: 'UserMenu',
  components: {
    NoticeIcon,
    screenfull
  },
  props: {
    mode: {
      type: String,
      // sidemenu, topmenu
      default: 'sidemenu'
    }
  },
  data() {
    return {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 5
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      },
      visible: false,
      confirmLoading: false,
      form1: this.$form.createForm(this),
      defApp: [],
      isFullscreen: false,
      // 公司
      comId: null,
      comList: [],
      selectedId: null,
      // 项目
      proId: null,
      proList: [],
      selectedProId: null,
      admintype: null // 是否是超管
    }
  },
  created() {
    this.systenantMypage()
    this.comId = this.$store.state.user.comId
    // this.comList = this.$store.state.user.comList
    // console.log(this.comList,"this.comList夏新夏新寻寻寻寻寻寻寻寻寻");
    this.proId = this.$store.state.user.proId
    this.proList = this.$store.state.user.proList
    // console.log(this.proId, 'this.proId')
    // console.log(this.proList, 'this.proList')
    this.admintype = this.$store.state.user.admintype
    this.selectedId = this.comId
    this.selectedProId = this.proId
  },
  computed: {
    ...mapGetters(['token', 'nickname', 'avatar', 'userInfo'])
  },
  // 设置signalr令牌
  async mounted() {
    // await this.$socket.authenticate(this.token)
  },
  methods: {
    ...mapActions(['Logout', 'MenuChange']),
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8

        return v.toString(16)
      })
    },
    logGuid() {
      console.log('生成guid如下：', this.guid())
    },
    testToken() {
      store.dispatch('refreshToken').then(res => {
        console.log('refreshToken', res)
        Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
        store.commit('SET_TOKEN', res.data.accessToken)
        Vue.ls.set('X-Access-Token', res.data.refreshToken)
      })
    },
    handleLogout() {
      this.$antdconfirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          return this.Logout({})
            .then(() => {
              setTimeout(() => {
                window.location.reload()
              }, 16)
            })
            .catch(err => {
              this.$message.error({
                title: '错误',
                description: err.message
              })
            })
        },
        onCancel() {}
      })
    },
    /* 全屏切换 */
    toggleFullscreen() {
      if (!screenfull.isEnabled) {
        message.error('您的浏览器不支持全屏模式')
        return
      }
      screenfull.toggle()
      if (screenfull.isFullscreen) {
        this.isFullscreen = false
      } else {
        this.isFullscreen = true
      }
    },
    // 获取企业信息
    systenantMypage() {
      let params = {
        PageNo: 1,
        PageSize: 10
      }
      
      _systenantMypage(params).then(res => {

        if (res.code == 200) {
          this.comList = res.data.rows
        } else {
        }
      })
    },
    handleSetting() {
      this.$router.push({ path: '/settings' })
    }
  },
  // signalr接收的信息
  sockets: {
    ReceiveMessage(data) {
      switch (data.messagetype) {
        case 0:
          this.$notification.info({
            message: data.title,
            description: data.message,
            placement: 'bottomRight',
            duration: null
          })
          break
        case 1:
          this.$notification.success({
            message: data.title,
            description: data.message,
            placement: 'bottomRight',
            duration: null
          })
          break
        case 2:
          this.$notification.warning({
            message: data.title,
            description: data.message,
            placement: 'bottomRight',
            duration: null
          })
          break
        case 3:
          this.$notification.error({
            message: data.title,
            description: data.message,
            placement: 'bottomRight',
            duration: null
          })
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  height: 55px;

  & .action {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .nickname {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background-color: #1890ff;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
  }
}
.appRedio {
  border: 0.0125rem solid #91d5ff;
  padding: 0.125rem 0.25rem;
  background: #e6f7ff;
  border-radius: 0.025rem;
  margin-bottom: 10px;
  color: #91d5ff;
  /*display: inline;
    margin-bottom:10px;*/
}

// ::v-deep .change-company {
//   width: 240px;
// }

::v-deep .change-company .ant-select-selection {
  border: none !important;
  color: #212a33;
  font-size: 0.2125rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

::v-deep .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
}
</style>
