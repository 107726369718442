<template>
  <transition name="showHeader">
    <div v-if="visible" class="header-animat">
      <a-layout-header
        v-if="visible"
        :class="[
          // fixedHeader && 'ant-header-fixedHeader',
          sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed',
          isFixed ? 'ant-header-fixedHeader' : ''
        ]"
        :style="{ padding: '0', height: '55px' }"
      >
        <div v-if="mode === 'sidemenu' && admintype == 1" class="header" >
          <!-- 隐藏应用的地方 -->
          <a-menu style="height: 55px; border-bottom: 0px;" mode="horizontal" :default-selected-keys="this.defApp">
            <a-icon
              v-if="device === 'mobile'"
              class="trigger"
              :type="collapsed ? 'menu-fold' : 'menu-unfold'"
              @click="toggle"
            />
            <a-icon
              v-else
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="toggle"
              style="padding-left: 20px; padding-right: 20px;"
            />
            <a-menu-item
              v-for="item in userInfo.apps"
              :key="item.code"
              style="top:0px; line-height: 55px; padding-left: 10px; padding-right: 10px"
              @click="switchApp(item)"
            >
              {{ item.name }}
            </a-menu-item>
            <user-menu></user-menu>
          </a-menu>
        </div>
        <div v-else-if="mode === 'sidemenu' && admintype !== 1" class="header">
          <div class="global_header">
            <div class="header_top">
              <!-- <a-icon
                v-if="device === 'mobile'"
                class="trigger"
                :type="collapsed ? 'menu-fold' : 'menu-unfold'"
                @click="toggle"
              />
              <a-icon
                v-else
                class="trigger"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                @click="toggle"
                style="padding-left: 20px; padding-right: 20px;"
              /> -->
              <div class="header_logo">
                <img :src="headerLogo" alt="" />
              </div>

              <div class="header_list">
                <div
                  :class="[isSelected == item.name ? 'isSelected' : '']"
                  v-for="item in appList"
                  :key="item.name"
                  @click="switchApp1(item)"
                >
                  {{ item.meta.title }}
                </div>
              </div>
            </div>

            <user-menu v-if="!isAlone"></user-menu>
          </div>

          <!-- 隐藏应用的地方 -->
          <a-menu
            v-if="false"
            style="height: 55px; border-bottom: 0px;"
            mode="horizontal"
            :default-selected-keys="this.defApp"
          >
            <a-icon
              v-if="device === 'mobile'"
              class="trigger"
              :type="collapsed ? 'menu-fold' : 'menu-unfold'"
              @click="toggle"
            />
            <a-icon
              v-else
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="toggle"
              style="padding-left: 20px; padding-right: 20px;"
            />

            <a-menu-item
              v-for="item in userInfo.apps"
              :key="item.code"
              style="top:0px; line-height: 55px; padding-left: 10px; padding-right: 10px"
              @click="switchApp(item)"
            >
              {{ item.name }}
            </a-menu-item>

            <user-menu></user-menu>
          </a-menu>
        </div>
        <div v-else :class="['top-nav-header-index', theme]">
          <div class="header-index-wide">
            <div>公司名称</div>
            <div class="header-index-left">
              <logo class="top-nav-header" :show-title="device !== 'mobile'" />
              <s-menu v-if="device !== 'mobile'" mode="horizontal" :menu="menus" :theme="theme" />
              <a-icon v-else class="trigger" :type="collapsed ? 'menu-fold' : 'menu-unfold'" @click="toggle" />
            </div>
            <!-- <user-menu class="header-index-right"></user-menu> -->
          </div>
        </div>
      </a-layout-header>
    </div>
  </transition>
</template>

<script>
import UserMenu from '../tools/UserMenu'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'
import { mixin } from '@/utils/mixin'
import { mapActions, mapGetters } from 'vuex'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import Vue from 'vue'
import { message } from 'ant-design-vue/es'
import Enumerable from 'linq'
import headerLogo from '@/assets/logo/header_logo.png'
import store from '@/store/index.js'
import router from '@/router/index.js'
export default {
  name: 'GlobalHeader',
  components: {
    UserMenu,
    SMenu,
    Logo
  },
  computed: {
    ...mapGetters(['userInfo']),

    isFixed() {
      // 根据自己的需求设置滚动到多少像素时开始固定侧边栏
      const threshold = 30
      if (this.scrollTop >= threshold) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    // this.defApp.push(Vue.ls.get(ALL_APPS_MENU)[0].code)
    var defautMenu = Enumerable.from(Vue.ls.get(ALL_APPS_MENU)).firstOrDefault(x => x.active === 'Y')
    if (defautMenu !== undefined) {
      this.defApp.push(defautMenu.code)
    }

    this.appList = this.userInfo.menus.filter(ele => {
      return ele.pid === 0
    })
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      // sidemenu, topmenu
      default: 'sidemenu'
    },
    menus: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    device: {
      type: String,
      required: false,
      default: 'desktop'
    }
  },
  data() {
    return {
      visible: true,
      oldScrollTop: 0,
      defApp: [],
      admintype: this.$store.state.user.admintype,
      appList: [],
      isSelected: '',
      allmenus: this.$store.state.user.allMenus1.menus,
      headerLogo,
      scrollTop: 0, // 记录页面滚动的高度
      isAlone: null
    }
  },
  mounted() {
    this.isAlone = window.__POWERED_BY_QIANKUN__

    document.addEventListener('scroll', this.handleScroll, {
      passive: true
    })
    this.isSelected = this.appList.length ? this.appList[0].name : ''
  },
  methods: {
    ...mapActions(['MenuChange']),
    ...mapActions(['MenuChange1']),

    /**
     * 应用切换
     */
    switchApp(row) {
      if (this.admintype == 1) {
        this.defApp = []
        const applicationData = this.userInfo.apps.filter(item => item.code === row.code)
        const hideMessage = message.loading('正在切换应用！', 0)
        this.MenuChange(applicationData[0])
          .then(res => {
            console.log(res, '==========switchApp=========')
            // return
            hideMessage()
          })
          .catch(err => {
            message.error('应用切换异常')
          })
      } else {
        this.isSelected = row.name

        this.MenuChange1({ row, allmenus: this.allmenus })
          .then(res => {
            console.log(res, '==========switchApp=========')

            // return
            // hideMessage()
            // eslint-disable-next-line handle-callback-err
          })
          .catch(err => {
            message.error('应用切换异常')
          })
      }
    },
    switchApp1(row) {
      this.isSelected = row.name
      this.$emit('updateSubMenu', row)
      this.$router.push(row.path)
    },

    handleScroll() {
      console.log('===========handleScroll==主页面=========')
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    },

    // handleScroll() {
    //   if (!this.autoHideHeader) {
    //     return
    //   }

    //   const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    //   if (!this.ticking) {
    //     this.ticking = true
    //     requestAnimationFrame(() => {
    //       if (this.oldScrollTop > scrollTop) {
    //         this.visible = true
    //       } else if (scrollTop > 300 && this.visible) {
    //         this.visible = false
    //       } else if (scrollTop < 300 && !this.visible) {
    //         this.visible = true
    //       }
    //       this.oldScrollTop = scrollTop
    //       this.ticking = false
    //     })
    //   }
    // },
    toggle() {
      this.$emit('toggle')
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.handleScroll, true)
  }
}
</script>

<style lang="less">
@import '../index.less';

.header-animat {
  // width: calc(100% - 310px);
  width: 100%;
  position: relative;
  z-index: @ant-global-header-zindex;
}

.showHeader-enter-active {
  transition: all 0.25s ease;
}

.showHeader-leave-active {
  transition: all 0.5s ease;
}

.showHeader-enter,
.showHeader-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
</style>
