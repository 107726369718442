var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{class:['layout', _vm.device]},[(_vm.isMobile() || (_vm.isSideMenu() && _vm.admintype == 1))?_c('div',[(_vm.isMobile())?_c('a-drawer',{attrs:{"placement":"left","wrapClassName":`drawer-sider ${_vm.navTheme}`,"closable":false,"visible":_vm.collapsed},on:{"close":_vm.drawerClose}},[_c('side-menu',{attrs:{"mode":"inline","menus":_vm.menus,"theme":_vm.navTheme,"collapsed":false,"collapsible":true},on:{"menuSelect":_vm.menuSelect}})],1):(_vm.isSideMenu() && _vm.admintype == 1)?_c('side-menu',{attrs:{"mode":"inline","menus":_vm.menus,"theme":_vm.navTheme,"collapsed":_vm.collapsed,"collapsible":true}}):_vm._e(),_c('a-layout',{class:[_vm.layoutMode, `content-width-${_vm.contentWidth}`],style:({ paddingLeft: _vm.contentPaddingLeft, minHeight: '100vh', width: '100%', position: 'absolute' })},[_c('global-header',{attrs:{"mode":_vm.layoutMode,"menus":_vm.menus,"theme":_vm.navTheme,"collapsed":_vm.collapsed,"device":_vm.device},on:{"toggle":_vm.toggle,"updateSubMenu":_vm.updateSubMenu}}),_c('a-layout-content',{style:({
          height: '100%',
          margin: '24px 24px 0',
          // paddingTop: fixedHeader ? '55px' : '0'
          paddingTop: '0'
        })},[(_vm.multiTab)?_c('multi-tab'):_vm._e(),_c('transition',{staticStyle:{"overscroll-behavior-x":"none"},attrs:{"name":"bounce","mode":"out-in"}},[_c('route-view')],1)],1),_c('a-layout-footer',[_c('global-footer')],1)],1)],1):_vm._e(),(_vm.admintype != 1)?_c('div',{staticClass:"flex flex-between"},[_c('a-layout',{class:[_vm.layoutMode, `content-width-${_vm.contentWidth}`],style:({ paddingLeft: '0px', minHeight: '100vh' })},[_c('global-header',{attrs:{"mode":_vm.layoutMode,"menus":_vm.menus,"theme":_vm.navTheme,"collapsed":_vm.collapsed,"device":_vm.device},on:{"toggle":_vm.toggle,"updateSubMenu":_vm.updateSubMenu}}),_c('a-layout-content',{style:({
          height: '100%',
          // margin: '20px 0 0',
          // paddingTop: fixedHeader ? '36px' : '0',
          paddingTop: '10px',
          display: 'flex',
          position: 'relative'
        })},[(_vm.multiTab)?_c('multi-tab'):_vm._e(),_c('div',{staticClass:"sidebar"},[_c('el-menu',{class:{ 'el-menu-self': _vm.isFixed },attrs:{"default-active":_vm.activeIndex,"mode":"vertical"}},[_vm._l((_vm.currentSubMenu),function(item,index){return [(item.children)?_c('div',[_c('el-submenu',{key:item.id,attrs:{"index":String(index)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.meta.title)+" ")]},proxy:true}],null,true)},_vm._l((item.children),function(child,ChildIndex){return _c('el-menu-item',{key:child.path,attrs:{"index":index + '-' + ChildIndex},on:{"click":function($event){return _vm.handleClick(child)}}},[_vm._v(" "+_vm._s(child.meta.title)+" ")])}),1)],1):_vm._e(),(!item.children)?_c('div',[_c('el-menu-item',{attrs:{"index":String(index)},on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" "+_vm._s(item.meta.title)+" ")])],1):_vm._e()]})],2)],1),_c('transition',{staticStyle:{"overscroll-behavior-x":"none"},attrs:{"name":"bounce","mode":"out-in"}},[_c('route-view')],1)],1),_c('a-layout-footer',[_c('global-footer')],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }