<!--
 * @Author: awenyjw
 * @Date: 2023-04-10 13:30:25
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-06-20 22:07:15
 * @FilePath: \hc-smartSupervision\src\components\Menu\SideMenu.vue
 * @Description: 
 * 
-->
<template>
  <a-layout-sider
    :class="['sider', isDesktop() ? null : 'shadow', theme, fixSiderbar ? 'ant-fixed-sidemenu' : null]"
    width="304px"
    :collapsible="collapsible"
    v-model="collapsed"
    :trigger="null"
  >
    <logo :collapsed="collapsed" />
    <s-menu :class="[theme=='dark'?'menu-dark-active':'menu-light-active']" :collapsed="collapsed" :menu="menus" :theme="theme" :mode="mode" @select="onSelect"></s-menu>
  </a-layout-sider>
</template>

<script>
import Logo from '@/components/tools/Logo'
import SMenu from './index'
import { mixin, mixinDevice } from '@/utils/mixin'

export default {
  name: 'SideMenu',
  components: { Logo, SMenu },
  mixins: [mixin, mixinDevice],
  props: {
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menus: {
      type: Array,
      required: true
    }
  },
  methods: {
    onSelect(obj) {
      this.$emit('menuSelect', obj)
    }
  }
}
</script>
<style lang="scss" scoped>
// 设置未选中菜单项鼠标滑过款式
// ::v-deep .ant-menu-item-active {
//   background-color: #e6f7ff !important;
// }
.menu-light-active {
  ::v-deep .ant-menu-item-active {
    background-color: #e6f7ff !important;
  }
}
.menu-dark-active {
  ::v-deep .ant-menu-item-active {
    background-color: #1890FF !important;
  }
}
</style>
