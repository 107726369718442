/**
 * 系统应用
 *
 * @author yubaoshan
 * @date 2020/5/26 19:06
 */
import { axios } from '@/utils/request'

/**
 * 登录
 *
 * @author yubaoshan
 * @date 2020/5/26 19:06
 */
export function login(parameter) {
  return axios({
    url: '/login',
    method: 'post',
    data: parameter
  })
}


// 移民后扶login
export function ymhfLogin(parameter) {
  return axios({
    url: '/platformsign',
    method: 'post',
    data: parameter
  })
}

/**
 * 登出
 *
 * @author yubaoshan
 * @date 2020/5/26 19:07
 */
export function logout(parameter) {
  return axios({
    url: '/logout',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取登录用户信息
 *
 * @author yubaoshan
 * @date 2020/5/26 19:08
 */
export function getLoginUser(parameter) {
  return axios({
    url: '/getLoginUser',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取租户开关
 *
 * @author yubaoshan
 * @date 2020/9/5 1:24
 */
export function getTenantOpen(parameter) {
  return axios({
    url: '/getTenantOpen',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取短信验证码
 *
 * @author yubaoshan
 * @date 2020/5/26 19:29
 */
export function getSmsCaptcha(parameter) {
  return axios({
    url: '/getSmsCaptcha',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取验证码开关
 *
 * @author Jax
 * @date 2021/1/22 00:00
 */
export function getCaptchaOpen(parameter) {
  return axios({
    url: '/getCaptchaOpen',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取验证图片 以及token
 *
 * @author Jax
 * @date 2021/1/22 00:00
 */
export function reqGet(data) {
  return axios({
    url: '/captcha/get',
    method: 'post',
    data
  })
}

/**
 * 滑动或者点选验证
 *
 * @author Jax
 * @date 2021/1/22 00:00
 */
export function reqCheck(data) {
  return axios({
    url: '/captcha/check',
    method: 'post',
    data
  })
}


// 获取图形验证码
export function _createImageVerify(data) {
  return axios({
    url: '/imageVerify/create',
    method: 'post',
    data
  })
}

// 验证图形验证码
export function _verifyImageVerify(data) {
  return axios({
    url: '/imageVerify/verify',
    method: 'post',
    data
  })
}
// 获取扫描登录二维码
export function _getQrCode(parameter) {
  return axios({
    url: '/getQrCode',
    method: 'get',
    params: parameter
  })
}
// 获取扫描登录二维码
export function _verifyQrCode(parameter) {
  return axios({
    url: `/checkQrCode/${parameter}`,
    method: 'get'
  })
}

// 获取短信验证码
export function _verifyPhoneVerify(data) {
  return axios({
    url: '/smsVerify/create',
    method: 'post',
    data,
    "Content-Type": "application/json-patch+json"
  })
}
// 注册
export function _verifyLogin(data) {
  return axios({
    url: '/regBySms',
    method: 'post',
    data,
    "Content-Type": "application/json-patch+json"
  })
}

// 验证码登录
export function _codeLogin(data) {
  return axios({
    url: '/loginBySms',
    method: 'post',
    data,
  })
}
// 忘记密码
export function _forgetPwd(data) {
  return axios({
    url: '/forgetPwd',
    method: 'post',
    data,
    "Content-Type": "application/json-patch+json"
  })
}
// 刷新token,不带id
export function _refreshToken(data) {
  return axios({
    url: '/refreshToken',
    method: 'post',
    data: data,
    "Content-Type": "application/json"
  })
}