import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
// import router from './router'
import { message, Modal, notification } from 'ant-design-vue' /// es/notification
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { _refreshToken } from '@/api/modular/system/loginManage'
console.log(process.env.VUE_APP_AXIOS_API_HEADER, 'axios实例', process.env.VUE_APP_API_BASE_URL)
// 创建 axios 实例
const service = axios.create({
  // baseURL: '/api', // api base_url
  // baseURL: 'https://ymhf.schaice.com/api', // api base_url
  // baseURL: process.env.VUE_APP_AXIOS_API_HEADER ? process.env.VUE_APP_API_BASE_URL + '/api' : '/api', // api base_url
  baseURL: process.env.VUE_APP_AXIOS_API_HEADER == 'true' ? process.env.VUE_APP_API_BASE_URL + '/api' : '/api', // api base_url

  timeout: 6000 // 请求超时时间
})

const instance = axios.create({
  // 配置选项
})

const err = error => {
  // debugger

  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)

    console.log(token, "==error.response===");

    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
      return Promise.reject(error)
    }
    if (error.response.status === 500) {
      if (data.message.length > 0) {
        message.error(data.message)
      }
      return Promise.reject(error)
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {

      // debugger
      console.log("用户信息失效了", error);

      // store.dispatch('Logout').then(() => {
      //   next({ path: '/user/login', query: { redirect: to.fullPath } })
      // })

      // Token 过期，通知主应用
      const event = new CustomEvent('tokenExpires')
      window.dispatchEvent(event)

      // 通知主应用跳转其他子应用
      const event1 = new CustomEvent('switchSystem', { detail: '/other-subsystem' })
      window.dispatchEvent(event1)



      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })


      // return store.dispatch('refreshToken').then(res => {

      //   debugger;

      //   console.log('refreshToken-err刷新token', res)
      //   if (res.success) {
      //     Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
      //     store.commit('SET_TOKEN', res.data.accessToken)
      //     Vue.ls.set('Access-Token', res.data.accessToken)
      //     Vue.ls.set('X-Access-Token', res.data.refreshToken)
      //     // 重新发起请求
      //     let config = error.config
      //     config.headers['Authorization'] = 'Bearer ' + res.data.accessToken
      //     return instance(config).then(res => {
      //       return res.data
      //     })
      //     // window.location.reload()
      //   } else {
      //     notification.error({
      //       message: '授权已过期',
      //       description: '请重新登录!'
      //     })
      //     store.dispatch('Logout').then(() => {
      //       window.location.reload()
      //       // setTimeout(() => {
      //       //   window.location.reload()
      //       // }, 1500)
      //     })
      //   }
      // })
    } else {
      return Promise.reject(error)
    }
  }
  // return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {

  let vuexToken = store.state.user.token
  const token = vuexToken || Vue.ls.get(ACCESS_TOKEN)
  const token1 = Vue.ls.get('ACCESS_TOKEN')
  const refreshToken = Vue.ls.get('X-Access-Token')

  if (token1 && config?.url === "/platformsign") {
    // config.headers['Host'] = 'ymhf.schaice.com '
    // config.headers['Origin'] = 'https://182.150.58.115:20443'
    // config.headers['Referer'] = 'https://182.150.58.115:20443/'
    config.headers['Authorization'] = 'Bearer ' + token1
  } else {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  if (refreshToken) {
    config.headers['X-Authorization'] = 'Bearer ' + refreshToken
  }
  return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */

service.interceptors.response.use(response => {
  // LocalStorage 存储的 token 和 refreshToken，不设定过期时间，由服务端统一处理
  // 修改后
  let token1 = Vue.ls.get(ACCESS_TOKEN);
  let token2 = response.headers['access-token'];
  let token3 = response?.data?.data?.accessToken;
  // console.log(ACCESS_TOKEN, token1, token2);

  if (token3 && token3 !== 'invalid_token') {
    store.commit('SET_TOKEN', token3)
    Vue.ls.set(ACCESS_TOKEN, token3 /*, 7 * 24 * 60 * 60 * 1000 */)
  }

  // 修改前
  // store.commit('SET_TOKEN', response.headers['access-token'])
  // if (response.headers['access-token'] && response.headers['access-token'] !== 'invalid_token') {
  //   store.commit('SET_TOKEN', response.headers['access-token'])
  //   Vue.ls.set(ACCESS_TOKEN, response.headers['access-token'] /*, 7 * 24 * 60 * 60 * 1000 */)
  // }

  if (response.headers['x-access-token']) {
    Vue.ls.set('X-Access-Token', response.headers['x-access-token'] /*, 7 * 24 * 60 * 60 * 1000 */)
  }
  if (response.request.responseType === 'blob') {
    return response
  }
  const resData = response.data
  const code = response.data.code

  return resData
}, err)





const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
