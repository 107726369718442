import {
    axios
} from '@/utils/request'
// 登录保持页面

// 刷新token接口 ==2023年5月4日 修改url refreshToken为refreshTenantId
export function _refreshToken(params) {
    return axios({
        url: '/refreshTenantId',
        method: 'post',
        data: params,
        'Content-Type': 'application/json-patch+json'
    })
}
//  获取所有行政区域列表
export function _getAllRegion(params) {
    return axios({
        url: '/region/all',
        method: 'get',
    })
}

//  获取所有行政区inpur  id是层级,四川省是1
export function _getLevelRegion(params) {
    return axios({
        url: 'region/level',
        method: 'get',
        params
    })
}

//  创建公司、租户
export function _createTenant(params) {
    return axios({
        url: '/systenant/createtenant',
        method: 'post',
        data: params,
    })
}
//  获取公司、租户下拉列表
export function _queryTenants(params) {
    return axios({
        url: '/systenant/querytenants',
        method: 'get',
        params: params,
    })
}

// 加入企业
export function _joinTenants(params) {
    return axios({
        url: '/usertenantrecord/jointenant',
        method: 'post',
        data: params,
        "Content-Type": "application/json-patch+json"
    })
}
/**
 * @description: 解散企业
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 16:39:09
 */
export function _dissolutionTenants(params) {
    return axios({
        url: '/systenant/dissolution',
        method: 'post',
        data: params,
        "Content-Type": "application/json-patch+json"
    })
}
// 退出企业
export function _quitTenants(params) {
    return axios({
        url: '/systenant/quit',
        method: 'post',
        data: params,
        "Content-Type": "application/json-patch+json"
    })
}
//  获取公司、租户下拉列表
export function _systenantMypage(params) {
    return axios({
        url: '/systenant/mypage',
        method: 'get',
        params: params,
    })
}
// 分页查询（我的申请记录）
export function _usertenantRecordMypage(params) {
    return axios({
        url: '/usertenantrecord/mypage',
        method: 'get',
        params: params,
    })
}

/**
 * 获取租户详情
 *
 * @author awenyjw
 * @date 2023-5-12 16:21:36
 */
export function SysTenantDetail(parameter) {
    return axios({
        url: '/sysTenant/detail',
        method: 'get',
        data: parameter
    })
}

