<template>
  <a-layout :class="['layout', device]">
    <!-- SideMenu -->

    <div v-if="isMobile() || (isSideMenu() && admintype == 1)">
      <a-drawer
        v-if="isMobile()"
        placement="left"
        :wrapClassName="`drawer-sider ${navTheme}`"
        :closable="false"
        :visible="collapsed"
        @close="drawerClose"
      >
        <side-menu
          mode="inline"
          :menus="menus"
          :theme="navTheme"
          :collapsed="false"
          :collapsible="true"
          @menuSelect="menuSelect"
        ></side-menu>
      </a-drawer>

      <side-menu
        v-else-if="isSideMenu() && admintype == 1"
        mode="inline"
        :menus="menus"
        :theme="navTheme"
        :collapsed="collapsed"
        :collapsible="true"
      ></side-menu>

      <a-layout
        :class="[layoutMode, `content-width-${contentWidth}`]"
        :style="{ paddingLeft: contentPaddingLeft, minHeight: '100vh', width: '100%', position: 'absolute' }"
      >
        <!-- layout header -->
        <global-header
          :mode="layoutMode"
          :menus="menus"
          :theme="navTheme"
          :collapsed="collapsed"
          :device="device"
          @toggle="toggle"
          @updateSubMenu="updateSubMenu"
        />

        <!-- layout content -->
        <a-layout-content
          :style="{
            height: '100%',
            margin: '24px 24px 0',
            // paddingTop: fixedHeader ? '55px' : '0'
            paddingTop: '0'
          }"
        >
          <multi-tab v-if="multiTab"></multi-tab>
          <!-- <transition name="page-transition">
          <route-view />
        </transition> -->
          <transition name="bounce" mode="out-in" style="overscroll-behavior-x: none;">
            <route-view />
          </transition>
        </a-layout-content>

        <!-- layout footer -->
        <a-layout-footer>
          <global-footer />
        </a-layout-footer>

        <!-- Setting Drawer (show in development mode) -->
        <!-- <setting-drawer v-if="!production && isShowSide"></setting-drawer> -->
      </a-layout>
    </div>

    <div v-if="admintype != 1" class="flex flex-between">
      <a-layout
        :class="[layoutMode, `content-width-${contentWidth}`]"
        :style="{ paddingLeft: '0px', minHeight: '100vh' }"
      >
        <!-- layout header -->
        <global-header
          :mode="layoutMode"
          :menus="menus"
          :theme="navTheme"
          :collapsed="collapsed"
          :device="device"
          @toggle="toggle"
          @updateSubMenu="updateSubMenu"
        />

        <!-- layout content -->
        <a-layout-content
          :style="{
            height: '100%',
            // margin: '20px 0 0',
            // paddingTop: fixedHeader ? '36px' : '0',
            paddingTop: '10px',
            display: 'flex',
            position: 'relative'
          }"
        >
          <multi-tab v-if="multiTab"></multi-tab>
          <!-- <transition name="page-transition">
          <route-view />
        </transition> -->

          <div class="sidebar">
            <el-menu :default-active="activeIndex" :class="{ 'el-menu-self': isFixed }" mode="vertical">
              <template v-for="(item, index) in currentSubMenu">
                <div v-if="item.children">
                  <el-submenu :index="String(index)" :key="item.id">
                    <template #title>
                      {{ item.meta.title }}
                    </template>

                    <el-menu-item
                      v-for="(child, ChildIndex) in item.children"
                      :key="child.path"
                      :index="index + '-' + ChildIndex"
                      @click="handleClick(child)"
                    >
                      {{ child.meta.title }}
                    </el-menu-item>
                  </el-submenu>
                </div>
                <div v-if="!item.children">
                  <el-menu-item :index="String(index)" @click="handleClick(item)">
                    {{ item.meta.title }}
                  </el-menu-item>
                </div>
              </template>
            </el-menu>
          </div>

          <transition name="bounce" mode="out-in" style="overscroll-behavior-x: none;">
            <route-view />
          </transition>
        </a-layout-content>

        <!-- layout footer -->
        <a-layout-footer>
          <global-footer />
        </a-layout-footer>
      </a-layout>
    </div>
  </a-layout>
</template>

<script>
import { triggerWindowResizeEvent } from '@/utils/util'
import { mapState, mapActions } from 'vuex'
import { mixin, mixinDevice } from '@/utils/mixin'
import config from '@/config/defaultSettings'

import RouteView from './RouteView'
import SideMenu from '@/components/Menu/SideMenu'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
import SettingDrawer from '@/components/SettingDrawer'
import { convertRoutes } from '@/utils/routeConvert'

export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: {
    RouteView,
    SideMenu,
    GlobalHeader,
    GlobalFooter,
    SettingDrawer
  },
  data() {
    return {
      // isShowSide: true,
      activeIndex: '0-0',
      production: config.production,
      collapsed: false,
      menus: [],
      admintype: this.$store.state.user.admintype,
      currentSubMenu: [],
      isActive: false,
      scrollTop: 0 // 记录页面滚动的高度
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    }),
    contentPaddingLeft() {
      if (!this.fixSidebar || this.isMobile()) {
        return '0'
      }
      if (this.sidebarOpened) {
        return '310px'
      }
      return '80px'
    },
    isFixed() {
      // 根据自己的需求设置滚动到多少像素时开始固定侧边栏
      const threshold = 55
      if (this.scrollTop >= threshold) {
        console.log('根据自己的需求设置滚动到多少像素时开始固定侧边栏')
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    sidebarOpened(val) {
      this.collapsed = !val
    },
    // 菜单变化
    mainMenu(val) {
      this.setMenus()
    }
    // $route(to, from) {
    //   // console.log(to, from, 'to, fromto, fromto, from')
    //   try {
    //     // 页面跳转适配
    //     // if (to.path == '/supervision/bigScreen/bigScreen') {
    //     //   this.isShowSide = false
    //     // } else if(from.path == '/supervision/bigScreen/bigScreen'){
    //     //   this.isShowSide = true
    //     // }
    //     // 无需跳转，始终显示，必要时参考上述写法
    //     this.isShowSide = true
    //   } catch (error) {}
    // },
  },
  created() {
    this.setMenus()
    /* const routes = convertRoutes(this.mainMenu.find(item => item.path === '/'))
    this.menus = (routes && routes.children) || [] */
    this.collapsed = !this.sidebarOpened
  },
  mounted() {
    const self = this
    window.addEventListener('scroll', function handleScroll(event) {
      self.handleScroll(event)
    })

    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    if (this.admintype !== 1) {
      this.updateSubMenu()
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapActions(['setSidebar']),
    // 重新生成
    setMenus() {
      const routes = convertRoutes(this.mainMenu?.find(item => item.path === '/'))

      this.menus = (routes && routes.children) || []

      console.log(this.menus, '===========this.menus===========')
    },

    handleScroll() {
      console.log('===handleScroll===')
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    },

    toggle() {
      this.collapsed = !this.collapsed
      this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    paddingCalc() {
      let left = ''
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '310px' : '80px'
      } else {
        left = (this.isMobile() && '0') || (this.fixSidebar && '80px') || '0'
      }
      return left
    },
    menuSelect() {},
    drawerClose() {
      this.collapsed = false
    },

    handleClick(row) {
      this.$router.push({ path: row.path })
    },

    updateSubMenu(row) {
      // console.log(row, '=====row $route====',this.$route)
      // 获取当前路径
      let currentName = ''

      if (!row) {
        currentName = 'com_welcome'
        this.$router.push({ path: '/socialDevelopment' })
      } else {
        currentName = row.name
      }

      // 查找匹配的元素树形结构的元素
      const matchingElement = this.findMatchingElements(this.menus, currentName)

      // 设置当前子菜单
      if (matchingElement && matchingElement.length) {
        // console.log(matchingElement[0], '--------')
        if (matchingElement[0].children && matchingElement[0].children.length) {
          this.currentSubMenu = matchingElement[0].children
        } else {
          this.currentSubMenu = matchingElement[0]
        }
      } else {
        this.currentSubMenu = []
      }

      if (this.currentSubMenu.length) {
        if (this.currentSubMenu[0]?.children) {
          this.activeIndex = '0-0'
        } else {
          this.activeIndex = '0'
        }
      }
    },
    // 定义一个递归函数，用于查找匹配的元素
    findMatchingElements(list, name) {
      let arr = []
      list.forEach(ele => {
        if (ele.name === name) {
          arr.push(ele)
        }
      })
      let res = arr && arr.length && arr.filter(ele => ele.children)
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  min-width: 3.3125rem;
  max-width: 3.3125rem;
  background-color: #dde2eb;
  position: relative;
}
::v-deep .el-submenu__title {
  font-size: 0.1625rem;
  padding-left: 0.225rem !important;
  color: #4e5969;
}

::v-deep .el-menu-item {
  font-size: 0.15rem;
  padding-left: 0.25rem !important;
  color: #4e5969;
}

::v-deep .el-menu-item.is-active {
  color: #409eff !important;
  background-color: #ecf5ff;
}

::v-deep .el-menu-self {
  transition: all 0.3s;
  position: fixed;
  top: 55px;
  left: 0;
  // left: 1.1%;
  width: 3.3125rem;
}
</style>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */

.page-transition-enter {
  opacity: 0;
}

.page-transition-leave-active {
  opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
